<template>
    <div class="skeleton-wrapper">
      <div 
        v-for="index in rows" 
        :key="index" 
        class="skeleton-line" 
        :class="{ short: isShort }"
      ></div>
    </div>
  </template>
  
<script>
    export default {
        props: {
            rows: {
                type: Number,
                default: 3, 
            },
            isShort: {
                type: Boolean,
                default: false,
            }
        }
    };
</script>
  
<style scoped>
.skeleton-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
  
.skeleton-line {
    height: 16px;
    width: 100%;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 4px;
}
  
.skeleton-line.short {
    width: 60%;
}
  
@keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
}
</style>
  