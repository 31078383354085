<template>
  <div v-loading.fullscreen.lock="this.$global.isLoadingSystem"  element-loading-spinner="fas fa-spinner fa-spin">
    <TopBar />
    <router-view :style="{top:(this.$global.charges_enabled === false || this.$global.payouts_enabled === false) && (this.$global.account_requirements !== '') && (isAgency) && (this.$global.isLoadRouterView) ? 50 : 0}"></router-view>
  </div>
</template>

<script>
import Vue from 'vue'
import vueCountryRegionSelect from 'vue-country-region-select'
import TopBar from './components/TopBar.vue';
Vue.use(vueCountryRegionSelect) 

export default {
  data() {
    return {
      isAgency: false,
    }
  },
  methods: {
    handleStorageChange(event) {
      // Trigger logout check on any localStorage change
      // localStorage.removeItem('companyGroupSelected');
      // localStorage.removeItem('subdomainAgency');
      // localStorage.removeItem('rootcomp');
      // this.$global.selectsGroupCompany.companyGroupList = null;
      // this.$store.dispatch('destroyToken')
      //   .then(response => {
      //     //this.$router.push({ name: 'Login' })
      //     window.document.location = '/login';
      //   })
    },
    initializeLayout() {
      if (!this.$rtl.isRTL) {
        // Just make sure rtl css is off when we are not on rtl
        this.$rtl.disableRTL();
      }
    }
  },
  mounted() {
    const storedData = this.$store.getters.userData;
    
    if(storedData){
      document.documentElement.style.setProperty('--bg-bar-color', storedData.sidebar_bgcolor);
      document.documentElement.style.setProperty('--text-bar-color', storedData.text_color);
      this.isAgency = storedData.user_type === 'userdownline';
    }

    this.initializeLayout();
    // Add event listener for storage events
    //window.addEventListener('storage', this.handleStorageChange);
  },
  beforeDestroy() {
    // Remove event listener when component is destroyed
    //window.removeEventListener('storage', this.handleStorageChange);
  },
  components: {
    TopBar
  }
};
</script>
<style >

:root {
  --background-color-primary: #f5f5f5;
  --background-color-secondary: #ffffff;
  --card-border-color: #ffffff;
  --primary-color: #374151;
  --accent-color: #212529;
  --primary-input-text-color: #212529;
  --dark: #212529;
  --text-primary-color: #101111;
  --element-size: 64px;
  --input-border-color: #101111;
  --btn-primary-color: #101111;
  --btn-primary-hover-color: #263148;  
  --em-primary: #374151;
  --bg-bar-color: #f5f5f5;
  --text-bar-color: #101111;
  --text-underline-color: #0833f7;
  --btn-black-color: #131920;
  --text-btn-black: #ddd;
  --link-color: #4286f4;  
  --base-text: #000000;
  --muted: #f4f4f5;
  --secondary-border-card: #EBEEF5;
}


/* :root.dark-theme {
  
  --background-color-primary: #2D3250;
  --background-color-secondary: #424769;
  --primary-color: #F6B17A;
  --accent-color: #c3c3c3;
  --primary-input-text-color: #e9e9e9;
  --gray-900: #e9e9e9;
  --dark: #d4d5d6;
  --text-primary-color: #ddd;
  --input-border-color: #ffffff;
  --bs-primary: #7267ef;
  --bs-secondary: #6c757d;
} */
:root.dark-theme {
  /* --background-color-primary: #1e1e1e; */
  --background-color-primary: #131920;
  --background-color-secondary: #1b232d;
  --card-border-color: #263240;
  --primary-color: #ffffff;
  --accent-color: #c3c3c3;
  --primary-input-text-color: #e9e9e9;
  --gray-900: #e9e9e9;
  --dark: #d4d5d6;
  --text-primary-color: #ddd;
  --input-border-color: #ffffff;
  --em-primary: #686868;
  --btn-primary-color: #686868;
  --btn-primary-hover-color: #5b5b65;
  --bs-secondary: #6c757d;
  --link-color: #4286f4;  
  --bg-bar-color: #131920;
  --text-bar-color: #ddd;
  --text-underline-color: #0833f7;
  --btn-black-color: #131920;
  --text-btn-black: #ddd;
  --base-text: #ffffff;
  --muted: #263240;
  --secondary-border-card: gray;
}

.bg-bar-color {
  background-color: var(--bg-bar-color) !important;
}

.text-bar-color {
    color: var(--text-bar-color) !important;
}

.text-underline-color {
  color: var(--text-underline-color) !important;
}

.btn-black-color {
  background-color: var(--btn-black-color) !important;
  color: var(--text-btn-black) !important;
}
.cursor-pointer{
  cursor: pointer;
}

.el-loading-spinner i {
  font-size: 40px;
  color: var(--btn-primary-color) !important;
}
.selerator-campaign-create{

height: 3px;
background: #d8d8d8;
border-radius: 3px;
margin: 25px 15px;
width: 100%;
}
</style>