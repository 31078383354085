/*!

 =========================================================
 * EXACT MATCH MARKETING TEAM 2021
 =========================================================
 
 */
 import VueTour from './plugins/tour';
import Global from './plugins/global';
import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch'
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import moment from 'moment-timezone'; // Import moment-timezone
import swal from 'sweetalert2';

// router setup
import router from './routes/router';
//import router from './routes/starterRouter';
import i18n from './i18n';
import './registerServiceWorker'
import { store } from './store/store'
import * as GmapVue from 'gmap-vue'

Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyCV2s4dDQivQr1rp8dC0f4BE1kG5Qh7zJ0',
    libraries: 'places,drawing', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})

// plugin setup
Vue.use(Global);
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(VueTour);
// Attach moment-timezone to Vue's prototype
Vue.prototype.$moment = moment;
const clientTimezone = moment.tz.guess();

moment.tz.setDefault(clientTimezone); // Set a default timezone if needed

// Set the source and target timezones
const sourceTimezone = clientTimezone; // Example: New York timezone
const targetTimezone = 'America/New_York'; // Example: Los Angeles timezone

// Parse the input time in the source timezone
const sourceMoment = moment.tz("2023-09-07 00:00:00", sourceTimezone);

// Convert the time to the target timezone
const targetMoment = sourceMoment.clone().tz(targetTimezone);
//console.log(moment("2023-09-06 21:40:00").format('YYYY-MM-DD HH:mm:ss'));
//console.log(targetMoment.format('YYYY-MM-DD HH:mm:ss'));
//console.log(clientTimezone);


router.beforeEach(async (to, from, next) => {
  $('head').find('style#cssCustomAgency').remove();
  $('head').find('style#cssGlobalTextColor').remove();
  $('head').find('style#cssGlobalLinkColor').remove();
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        name: 'Login',
      })
    }else{
      /** VALIDATE MODULE AUTHORIZED */
      var global = Vue.prototype.$global
      // var user = store.getters.userData
      var user = store.getters.userData;
      var companyID = user['company_id'];
      var roleid = user['role_id'];
      var userType = user['user_type'];
      var systemuser = user['systemuser'];
      var isAccExecutive = user['isAccExecutive'];
      global.menuUserType = userType;
      global.systemUser = systemuser;
      global.isAccExecutive = isAccExecutive;
      var menuEnabled = to.meta.menuEnabled
      
      if (typeof(userType) != 'undefined') {
        if(userType == 'client') {
          if (global.clientPaymentFailed) {
            next();
          }
        }
      }

      /** CHECK FOR  VIEW MODE AS ANOTHER USER*/
      if (localStorage.getItem('userDataOri') === null) {
        global.globalviewmode = false;
      }else{
        global.globalviewmode = true;
      }
      /** CHECK FOR  VIEW MODE AS ANOTHER USER*/

      /** CHECK FOR DENY PERMISSIONS*/
      global.user_permissions = null
      if(user.user_permissions){
        const userPermissions = JSON.parse(user.user_permissions)

        global.user_permissions = userPermissions
      }
      /** CHECK FOR DENY PERMISSIONS*/

      /** CHECK FOR DENY PERMISSIONS VIEW MODE*/
      if(user.user_permissions_view_mode){
        const userPermissionsViewMode = user.user_permissions_view_mode

        global.user_permissions = userPermissionsViewMode
      }
      /** CHECK FOR DENY PERMISSIONS VIEW MODE*/

      /** CHECK FOR SYS ID */
      if (global.idsys == "" || global.companyrootname == "") {
        let domainorsub = ""
        
        if(localStorage.getItem('subdomainAgency') && localStorage.getItem('subdomainAgency') != 'undefined') {
          domainorsub = localStorage.getItem('subdomainAgency');
        }

        else {
          domainorsub = window.location.hostname;
          localStorage.removeItem('subdomainAgency')
        }
        global.isLoadingSystem = false
        try {
          global.isLoadingSystem = true
          const response = await store.dispatch('getDomainorSubInfo', {
            domainorsub: domainorsub,
          });

          global.idsys = response.params.idsys;    
          global.companyrootname = response.params.companyrootname;
          global.companyrootlegalname = response.params.companyrootlegalname;
          global.companyrootnameshort = response.params.companyrootnameshort;
          global.companyrootaddres = response.params.companyrootaddres;
          global.companyrootcity = response.params.companyrootcity;
          global.companyrootzip = response.params.companyrootzip;
          global.companyrootstatecode = response.params.companyrootstatecode;
          global.companyrootstatename = response.params.companyrootstatename;
          global.companyrootdomain = response.params.companyrootdomain;
          global.companyrootsubdomain = response.params.companyrootsubdomain;
          global.companyrootphone = response.params.companyrootphone;
          
          global.userrootname = response.params.userrootname;
          global.userrootemail = response.params.userrootemail;
          global.userrootaddres = response.params.userrootaddres;
          global.userrootcity = response.params.userrootcity;
          global.userrootzip = response.params.userrootzip;
          global.userrootstatecode = response.params.userrootstatecode;
          global.userrootstatename = response.params.userrootstatename;
          global.sppubkey = response.params.sppubkey;
          global.recapkey = response.params.recapkey;
          global.charges_enabled = response.params.charges_enabled;
          global.payouts_enabled = response.params.payouts_enabled;
          global.account_requirements = response.params.account_requirements;
          if (response.params.rootcomp == "T") {
            global.rootcomp = true;
          }else{
            global.rootcomp = false;
            if(localStorage.getItem('rootcomp') == 'true') {
              global.rootcomp = true;
            }
          }

          if(global.rootcomp){
            const updatedData = {
              rootcomp: global.rootcomp
            }
            
            store.dispatch('updateUserData', updatedData);
          }

          if (response.params.agencyplatformroot == 'T') {
            global.agencyplatformroot = true;
          }else{
            global.agencyplatformroot = false;
          }

          
          global.rootpaymentterm = response.paymenttermlist;

          global.rootcustomsidebarleadmenu = response.rootsidemenu;
          global.customsidebarleadmenu = response.sidemenu;
          global.agencysidebar = response.agencysidebar;
          const menuItems = ['local', 'locator', 'enhance'];
          if (global.agencysidebarsetting !== '') {
            menuItems.forEach(item => {
              global.agencysidebar[item] = typeof response.agencysidebar[item] === 'undefined' || typeof response.agencysidebar[item] === null  ? false : true;
            });
          }           

        } 
        catch (error) {
          console.error(error);
        } finally {
          global.isLoadingSystem = false
        }
      }
      /** CHECK FOR SYS ID */

      /** SETUP GLOBAL THINGS */
      if (user.user_type == 'client' && (user.company_name == '' || user.company_name == null)) {
        global.globalCompanyName = user.companyparentname; 
      }else{
        global.globalCompanyName = user.company_name;
      }
      document.title = user.company_name;
      if(user.company_logo != null && user.company_logo != '') {
        global.globalCompanyPhoto = user.company_logo;
        $('link[rel="icon"]').attr('href', user.company_logo);
        $('link[rel="apple-touch-icon"]').attr('href', user.company_logo);
      }else{
        if (user.user_type == 'client' && user.companyparentlogo != null && user.companyparentlogo != '') {
          global.globalCompanyPhoto = user.companyparentlogo;
          $('link[rel="icon"]').attr('href', user.companyparentlogo);
          $('link[rel="apple-touch-icon"]').attr('href', user.companyparentlogo);
        }else{
          global.globalCompanyPhoto = '/img/logoplaceholder.png'
          $('link[rel="icon"]').attr('href', '/favicon.png');
          $('link[rel="apple-touch-icon"]').attr('href', '/favicon.png');
        }
      }
      if(user.profile_pict != null && user.profile_pict != '') {
        global.globalProfilePhoto = user.profile_pict;
      }else{
        global.globalProfilePhoto = '/img/placeholder.jpg'
      } 
      /** SETUP GLOBAL THINGS */

      /** SETUP CUSTOM THEME */
      
      global.globalBoxBgColor = user.box_bgcolor;
      global.globalTextColor = user.text_color;
      global.globalLinkColor = user.link_color;
      
      global.globalTemplateBgColor = user.template_bgcolor;
      global.globalSidebarBgColor = user.sidebar_bgcolor;
      global.globalFontTheme = user.font_theme;
      
      let getParentsColor = localStorage.getItem('parentsColor')
      if(getParentsColor){
        getParentsColor = JSON.parse(getParentsColor)

        global.globalSidebarBgColor = getParentsColor.sidebar_bgcolor;
        global.globalTextColor = getParentsColor.text_color;
      }


      $('head').append('<style id="cssCustomAgency">' +
        '.sidebar:before{border-bottom-color:' +  global.globalSidebarBgColor + ' !important;} ' +
        // '.clickable-rows .el-table, .el-table__expanded-cell {background-color:' + global.globalBoxBgColor + ' !important;} ' +
        // '.label-border-box {background-color:' + global.globalBoxBgColor + ' !important;} ' +
        // '.form-control[disabled] {background-color: rgba(0, 0, 0, 0);color: rgba(255, 255, 255, 0.2);border-color:#2b3553}' +
        // '.input-group-prepend .input-group-text, .input-group-append .input-group-text {border-color:' + global.globalTextColor + '} ' +
        // '.form-control {border-color:' + global.globalTextColor + '} ' +
        '</style>');
      $('body').css('font-family',global.globalFontTheme);
      
      if (to.name != "Login" && to.name != "Register" && to.name != "Agency Register" && to.name != "TermUse" && to.name != "PrivacyPolicy") {
        $('head').append('<style id="cssGlobalTextColor">#sidebarCompanyName, .sidebar-item-wrapper {color:' +  global.globalTextColor + ' !important;}</style>');
        // $('head').append('<style id="cssGlobalLinkColor">body a, a span {color:' +  global.globalLinkColor + ' !important;}</style>');
      }

      /** SETUP CUSTOM THEME */

      /** SETUP CUSTOM SIDEMENU */
      if(user.leadlocalname != null && user.leadlocalname != '') {
        global.globalModulNameLink.local.name = user.leadlocalname;
      }
      if(user.leadlocalurl != null && user.leadlocalurl != '') {
        global.globalModulNameLink.local.url = user.leadlocalurl;
      }
      if(user.leadlocatorname != null && user.leadlocatorname != '') {
        global.globalModulNameLink.locator.name = user.leadlocatorname;
      }
      if(user.leadlocatorurl != null && user.leadlocatorurl != '') {
        global.globalModulNameLink.locator.url = user.leadlocatorurl;
      }
      global.globalModulNameLink.enhance.name = user.leadenhancename;
      global.globalModulNameLink.enhance.url = user.leadenhanceurl;
      /** SETUP CUSTOM SIDEMENU */

      if(userType == 'client' && global.clientPaymentFailed == false) {
        /** CHECK FOR CLIENT DISABLED MENU CAMPAIGN MANAGEMENT */
        store.dispatch('getUserData',{
            usrID: 'only/' + user.id,
        }).then(response => {
            if (response.disable_client_add_campaign == 'T') {
              global.disabledaddcampaign = false;
              if (typeof(to.meta.itemname) != 'undefined' && to.meta.itemname == 'campaignmanagement') {
                next({
                  name: 'Dashboard',
                })
              }
            }else{
              global.disabledaddcampaign = true;
            }
        },error => {
            
        });
        /** CHECK FOR CLIENT DISABLED MENU CAMPAIGN MANAGEMENT */
        store.dispatch('checkUserSetupComplete', {
            usrID: user['id'],
        }).then(response => {
            global.paymentStatusFailed = response.paymentStatusFailed;
            global.checkClientModule(response.setupcomplete,response.accessmodule);

            /* FOR SIDEBAR */
            if(typeof(response.rootsidemenu.enhance) === 'undefined' || typeof(response.rootsidemenu.enhance) === null) {
              let userData = store.getters.userData;
              
              // overwrite leadenhancename and leadenhanceurl in localstorage
              if(userData.leadenhancename != null || userData.leadenhanceurl != null) {
                userData.leadenhancename = null;
                userData.leadenhanceurl = null;

                global.globalModulNameLink.enhance.name = userData.leadenhancename;
                global.globalModulNameLink.enhance.url = userData.leadenhanceurl;
                
                // update userData In LocalStorage
                const updatedData = {
                  leadenhancename: userData.leadenhancename,
                  leadenhanceurl: userData.leadenhanceurl,
                }

                store.dispatch('updateUserData', updatedData);
                // update store.getters.userData to sync with localStorage
                store.dispatch('fetchUserFromLocalStorage');
                // reload the page to update router enhance
                window.location.href = '/';
              }
            } else {
              let userData = store.getters.userData;
              
              // overwrite leadenhancename and leadenhanceurl in localstorage
              if(userData.leadenhancename == null || userData.leadenhanceurl == null) {
                userData.leadenhancename = (typeof(response.sidemenu.enhance) !== 'undefined') ? response.sidemenu.enhance.name : response.rootsidemenu.enhance.name;
                userData.leadenhanceurl = (typeof(response.sidemenu.enhance) !== 'undefined') ? response.sidemenu.enhance.url : response.rootsidemenu.enhance.url;

                global.globalModulNameLink.enhance.name = userData.leadenhanceurl;
                global.globalModulNameLink.enhance.url = userData.leadenhanceurl;

                // update userData In LocalStorage
                const updatedData = {
                  leadenhancename: userData.leadenhancename,
                  leadenhanceurl: userData.leadenhanceurl,
                }

                store.dispatch('updateUserData', updatedData);
                // update store.getters.userData to sync with localStorage
                store.dispatch('fetchUserFromLocalStorage');
                // reload the page to update router enhance
                location.reload();
              }
            }
            /* FOR SIDEBAR */

            if (typeof to.meta.menuname != 'undefined') {
              if (to.meta.menuname == 'menuAdsDesign') {
                menuEnabled = Vue.prototype.$global.menuAdsDesign
              }else if (to.meta.menuname == 'menuCampaign') {
                menuEnabled = Vue.prototype.$global.menuCampaign
              }else if (to.meta.menuname == 'menuLeadsPeek') {
                menuEnabled = Vue.prototype.$global.menuLeadsPeek
              }else if (to.meta.menuname == 'settingMenuShow') {
                menuEnabled = Vue.prototype.$global.settingMenuShow
              }
            }

            if (user.customer_card_id != '') {
              global.creditcardsetup = true;
            }else{
              global.creditcardsetup = false;
            }

            if (user.questionnaire_setup_completed == 'T') {
              global.questionnairesetup = true;
            }else{
              global.questionnairesetup = false;
            }
            
            if(to.name == 'Card Setting' && user.customer_card_id == '') {
              //console.log('A');
              next({
                name: 'Profile Setup',
              })
            }else if (menuEnabled && to.meta.clientTypeAccess.includes(store.getters.getUserType)) {
              next()
            }else{
              //console.log('B');
              next({
                name: 'Profile Setup',
              })
            }

            global.clientPaymentFailed = false;
            
            if (response.setupcomplete == 'F' && response.accessmodule == 'paymentfailed') {
              global.clientPaymentFailed = true;
              global.failedCampaignNumber = response.fcampid;
              global.failedInvoiceAmount = response.finamt;
              
              next({
                name: 'Card Setting',
              })
            }

            // global.clientsidebar = [];
            // if (response.clientsidebar != []) {
            //   global.clientsidebar = response.clientsidebar
            // }
            global.clientsidebar = response.clientsidebar
            const menuItems = ['local', 'locator', 'enhance'];
            if (global.clientsidebar != []) {
              menuItems.forEach(item => {
                global.clientsidebar[item] = typeof response.clientsidebar[item] == 'undefined' || typeof response.clientsidebar[item] === null ? false : true;
              });
            }

            

        },error => {
          console.log('Token expired');
          localStorage.removeItem('access_token');
          localStorage.removeItem('userData');
          localStorage.removeItem('userRole');
          document.location = "/";
        });
      
      }else{
        store.dispatch('GetRoleList', {
              companyID: companyID,
              getType:'getrolemodule',
              roleID:roleid,
              usrID: user['id'],
          }).then(response => {

              global.isBeta = response.isBeta;
              global.betaFeature = response.betaFeature;

              global.paymentStatusFailed = response.paymentStatusFailed;
              global.agencyPaymentFailed = false;
              if ((global.menuUserType == 'userdownline' || global.menuUserType == 'user') && response.accessmodule_agency == 'paymentfailed') {
                global.agencyPaymentFailed = true;
                global.failedCampaignNumber = response.fcampid;
                global.failedInvoiceAmount = response.finamt;
                // console.log('bbb menuUserType ' + global.menuUserType);
                // console.log('bbb accessmodule_agency ' + response.accessmodule_agency);
                // console.log('bbb agencyPaymentFailed ' + global.agencyPaymentFailed);
                // console.log('bbb failedCampaignNumber ' + global.failedCampaignNumber);
                // console.log('bbb failedInvoiceAmount ' + global.failedInvoiceAmount);
                if(to.name != 'Card Setting') {
                  next({
                    name: 'Card Setting',
                  })
                }
              }

              //this.rolemodulelist = response;
              global.agencyfilteredmodules = response.agencyFilteredModules;
              global.agency_side_menu = response.agency_side_menu

              /* FOR SIDEBAR */
              if(typeof(response.rootsidemenu.enhance) === 'undefined') {
                let userData = store.getters.userData;
                
                // overwrite leadenhancename and leadenhanceurl in localstorage
                if(userData.leadenhancename != null || userData.leadenhanceurl != null) {          
                  userData.leadenhancename = null;
                  userData.leadenhanceurl = null;

                  global.globalModulNameLink.enhance.name = userData.leadenhancename;
                  global.globalModulNameLink.enhance.url = userData.leadenhanceurl;

                  // update userData In LocalStorage
                  const updatedData = {
                    leadenhancename: userData.leadenhancename,
                    leadenhanceurl: userData.leadenhanceurl,
                  }
  
                  store.dispatch('updateUserData', updatedData);
                  // update store.getters.userData to sync with localStorage
                  store.dispatch('fetchUserFromLocalStorage');
                  // reload the page to update router enhance
                  window.location.href = '/';
                }
              } else {
                let userData = store.getters.userData;
                
                // overwrite leadenhancename and leadenhanceurl in localstorage
                if(userData.leadenhancename == null || userData.leadenhanceurl == null) {
                  userData.leadenhancename = (typeof(response.sidemenu.enhance) !== 'undefined') ? response.sidemenu.enhance.name : response.rootsidemenu.enhance.name;
                  userData.leadenhanceurl = (typeof(response.sidemenu.enhance) !== 'undefined') ? response.sidemenu.enhance.url : response.rootsidemenu.enhance.url;

                  global.globalModulNameLink.enhance.name = userData.leadenhanceurl;
                  global.globalModulNameLink.enhance.url = userData.leadenhanceurl;

                  // update userData In LocalStorage
                  const updatedData = {
                    leadenhancename: userData.leadenhancename,
                    leadenhanceurl: userData.leadenhanceurl,
                  }
  
                  store.dispatch('updateUserData', updatedData);
                  // update store.getters.userData to sync with localStorage
                  store.dispatch('fetchUserFromLocalStorage');
                  // reload the page to update router enhance
                  location.reload();
                }
              }
              /* FOR SIDEBAR */

              if(!global.systemUser){
                if(response.is_whitelabeling == 'F'){
                  const parentsColor = response.colors_parent
                  global.globalTextColor = response.colors_parent.text_color;
                  global.globalSidebarBgColor = response.colors_parent.sidebar_bgcolor;
                  document.documentElement.style.setProperty('--bg-bar-color', response.colors_parent.sidebar_bgcolor);
                  document.documentElement.style.setProperty('--text-bar-color', response.colors_parent.text_color);

                  localStorage.setItem('parentsColor', JSON.stringify(parentsColor))
                } else {
                  localStorage.removeItem('parentsColor')
                }
              }

              if(response.paymenttermlist != ''){
                global.rootpaymentterm = response.paymenttermlist;
              }

              if(response.rootPaymentTermsNewAgencies != ''){
                const paymenttermlist = response.paymenttermlist.map(term => {
                  const matchingTerm = response.rootPaymentTermsNewAgencies.find(
                    agencyTerm => agencyTerm.term === term.value
                  );
                  
                  return {
                    ...term,
                    status: matchingTerm ?  matchingTerm.status : false
                  }
                })
                
                global.isRootPaymentTermsNewAgencies = true
                global.rootpaymenttermnewagencies = paymenttermlist
              } else {
                global.isRootPaymentTermsNewAgencies = false
              }

              if (response.agencyDefaultModules != '') {
                global.agencyDefaultModules = response.agencyDefaultModules
              }


              global.checkModuleRole(response.modules,response.setupcomplete);
              if ((global.menuUserType == 'userdownline' || global.menuUserType == 'user') && !global.systemUser) {
                /** FOR AGENCY THAT NEED TO CONNECT THEIR ACCOUNT BEFORE START ADD CLIENT */
                global.statusaccountconnected = response.accountconnected;
                if (response.accountconnected == 'completed' && response.package_id != '') {
                  global.stripeaccountconnected = true;
                }else if (response.accountconnected == '' && response.paymentgateway != 'stripe' && response.package_id != '') {
                  global.stripeaccountconnected = true;
                }else if (response.accountconnected == '' && response.paymentgateway == 'stripe' && (response.package_id != '' && user.manual_bill == 'T')) {
                  global.stripeaccountconnected = true;
                }else if (response.accountconnected == 'failed' && response.paymentgateway == 'stripe' && (response.package_id != '' && user.manual_bill == 'T')) {
                  global.stripeaccountconnected = true;
                }else{
                  global.stripeaccountconnected = false;
                  if (response.accountconnected == '') {
                    global.statusaccountconnected = 'You need to connect your Stripe account prior to adding any clients.(go to system setting->general setting->connect your account)';
                  }else if (response.accountconnected == 'pending') {
                    global.statusaccountconnected = 'Please continue complete the form to connect your account with Stripe before start to add client (go to system setting->general setting->connect your account)';
                  }else if (response.accountconnected == 'inverification') {
                    global.statusaccountconnected = 'Your account is still being verified by Stripe, please check your email or login to your Stripe account for additional steps required by Stripe.';
                  }

                  if ($('#popstatusaccountconnect').length == 0 && user.customer_card_id != '' && global.statusaccountconnected != 'completed' && user.manual_bill == 'F') {
                    Vue.prototype.$notify({
                        id:'popstatusaccountconnect',
                        message: global.statusaccountconnected,
                        timeout: 0,
                        icon: 'fas fa-megaphone',
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'danger',
                        ignoreDuplicates: true,
                      });
                  }
                  
                }
                /** FOR AGENCY THAT NEED TO CONNECT THEIR ACCOUNT BEFORE START ADD CLIENT */

                /** WARNING FOR AGENCY NOT HAVE CUSTOMER CARD YET */
                if (user.customer_card_id == '' && user.profile_setup_completed == 'T') {
                  if (!document.getElementById('popstatuspaymentsetupfailed')) {
                    Vue.prototype.$notify({
                        id:'popstatuspaymentsetupfailed',
                        message: 'Complete your payment setup first to fully use the system. (Go to Profile -> Payment Setup)',
                        timeout: 0,
                        icon: 'fas fa-megaphone',
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'danger',
                        ignoreDuplicates: true,
                    });
                  }
                }
                /** WARNING FOR AGENCY NOT HAVE CUSTOMER CARD YET */

              }else if (global.menuUserType == 'sales' && global.systemUser) {
                  global.statusaccountconnected = response.accountconnected;

                  if (response.accountconnected == 'completed') {
                    global.stripeaccountconnected = true;
                  }else{
                    global.stripeaccountconnected = false;
                    if (response.accountconnected == '') {
                      global.statusaccountconnected = 'You need to connect your Stripe account to activated your Sales Account';
                    }else if (response.accountconnected == 'pending') {
                      global.statusaccountconnected = 'Please continue complete the form to connect your account with Stripe before start';
                    }else if (response.accountconnected == 'inverification') {
                      global.statusaccountconnected = 'Your account is still being verified by Stripe, please check your email or login to your Stripe account for additional steps required by Stripe.';
                    }

                    if ($('#popstatusaccountconnect').length == 0 && user.customer_card_id == '' && global.statusaccountconnected != 'completed') {
                      Vue.prototype.$notify({
                          id:'popstatusaccountconnect',
                          message: global.statusaccountconnected,
                          timeout: 0,
                          icon: 'fas fa-megaphone',
                          horizontalAlign: 'right',
                          verticalAlign: 'top',
                          type: 'danger',
                          ignoreDuplicates: true,
                        });
                    }

                  }
              }

              if (typeof to.meta.menuname != 'undefined') {
                if (to.meta.menuname == 'menuAdsDesign') {
                  menuEnabled = Vue.prototype.$global.menuAdsDesign
                }else if (to.meta.menuname == 'menuCampaign') {
                  menuEnabled = Vue.prototype.$global.menuCampaign
                }else if (to.meta.menuname == 'menuLeadsPeek') {
                  menuEnabled = Vue.prototype.$global.menuLeadsPeek
                }else if (to.meta.menuname == 'settingMenuShow') {
                  menuEnabled = Vue.prototype.$global.settingMenuShow
                }
              }
              
              if (user.customer_card_id != '') {
                global.creditcardsetup = true;
              }else{
                global.creditcardsetup = false;
              }

              if (user.questionnaire_setup_completed == 'T') {
                global.questionnairesetup = true;
              }else{
                global.questionnairesetup = false;
              }


              if(to.name == 'Card Setting' && user.customer_card_id == '') {
                //console.log('C');
                next({
                  name: 'Profile Setup',
                })
              }else if (menuEnabled && to.meta.clientTypeAccess.includes(store.getters.getUserType)) {
                next()
              }else{
                //console.log('D');
                next({
                  name: 'Profile Setup',
                })
              }

          },error => {
              console.log('Token expired');
              localStorage.removeItem('access_token');
              localStorage.removeItem('userData');
              localStorage.removeItem('userRole');
              localStorage.removeItem('userDataOri');
              document.location = "/";
          });
      }

      /** VALIDATE MODULE AUTHORIZED */

      /*if(to.meta.menuEnabled && to.meta.clientTypeAccess.includes(store.getters.getUserType)) {
        next()
      }else{
        next({
          name: 'Profile Setup',
        })
      }*/
      //if(to.matched.some(record => record.meta.menuEnabled) && ) {

      //}
      //next()
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters.loggedIn) {
      /* IF SSO, AUTO LOGOUT CURRENT USER AND LOGIN NEW USER */
      if(to.path === '/sso' && to.query.token) {
        // validation token
        try {
          const response = await store.dispatch('ssoValidationToken', {
            subdomain: window.location.hostname,
            token: to.query.token,
            currentUserID: store.getters.userData.id
          });

          // console.log(response);

          if(response.status == 200 && response.data.message == "Access Token Valid") {
            const response2 = await store.dispatch('retrieveUserData',{})

            if(response2 == 'success') {
              var global = Vue.prototype.$global
              
              const userData = store.getters.userData;
                        
              /** SET TO STORAGE FOR SIDEMENU */
              // userData.leadlocalname = global.globalModulNameLink.local.name;
              // userData.leadlocalurl = global.globalModulNameLink.local.url;

              // userData.leadlocatorname = global.globalModulNameLink.locator.name;
              // userData.leadlocatorurl = global.globalModulNameLink.locator.url;
              
              global.globalModulNameLink.local.name = userData.leadlocalname;
              global.globalModulNameLink.local.url = userData.leadlocalurl;

              global.globalModulNameLink.locator.name = userData.leadlocatorname;
              global.globalModulNameLink.locator.url = userData.leadlocatorurl;

              global.globalModulNameLink.enhance.name = userData.leadenhancename;
              global.globalModulNameLink.enhance.url = userData.leadenhanceurl;

              userData.idsys = global.idsys;

              const updatedData = {
                leadlocalname: global.globalModulNameLink.local.name,
                leadlocalurl: global.globalModulNameLink.local.url,
                leadlocatorname: global.globalModulNameLink.locator.name,
                leadlocatorurl: global.globalModulNameLink.locator.url,
                idsys: global.idsys
              }

              store.dispatch('updateUserData', updatedData);
              /** SET TO STORAGE FOR SIDEMENU */
              
              const usetupcompleted = store.getters.userData.profile_setup_completed
              if (usetupcompleted == "F") {
                window.document.location = "/user/profile-setup";
              }else{
                //const userData = store.getters.userData;
                if (userData.menuLeadspeek == true && userData.user_type == 'client' && userData.leadspeek_type == 'locator') {
                  window.document.location = "/" + global.globalModulNameLink.locator.url + '/dashboard';
                }else if (userData.menuLeadspeek == true && userData.user_type == 'client' && userData.leadspeek_type == 'local') {
                  window.document.location = "/" + global.globalModulNameLink.local.url + '/dashboard';
                }else if (((userData.user_type == 'sales' && userData.status_acc != 'completed') || (userData.user_type == 'sales' && userData.status_acc == 'completed' && userData.isAccExecutive == 'F') ) && userData.systemuser) {
                  window.document.location = "/configuration/sales-connect-account";
                }else if ((userData.user_type == 'user' || userData.user_type == 'userdownline' || userData.user_type == 'sales') && userData.systemuser) {
                  window.document.location = "/configuration/agency-list";
                }else if ((userData.user_type == 'user' || userData.user_type == 'userdownline') && (userData.charges_enabled == false || userData.payouts_enabled == false)) {
                  window.document.location = "/configuration/general-setting";
                }else if (userData.user_type == 'user') {
                  window.document.location = "/" + global.globalModulNameLink.local.url + '/dashboard';
                }else{
                  //window.document.location = "/user/profile-setup";
                  window.document.location = "/" + global.globalModulNameLink.local.url + '/dashboard';
                }
              }
            }
            else{
              swal.fire({
                icon: 'error',
                title: 'Sorry!',
                text: 'There is currently an update processing on the platform. Please wait five minutes and try again.',
              })
            }
          }

        } 
        catch (error) {
          console.log(error);
        }
        // validation token
      }
      /* IF SSO, AUTO LOGOUT CURRENT USER AND LOGIN NEW USER */

      var user = store.getters.userData
      var defaultRoute = 'Profile Setup';
      if (user['systemuser']) {
        defaultRoute = 'Agency List';
      }else if (user['profile_setup_completed'] == 'T'){
        defaultRoute = 'Dashboard'
      }
      next({
        name: defaultRoute,
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  Vue.prototype.$global.isLoadRouterView = true;
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store: store, 
  i18n
});