<template>
    <div v-if="isShow" style="position: relative; display: inline-block; width: 100%;">
        <slot></slot>
        <el-tag type="primary" size="mini" class="beta-badge" :class="badgePositionClass">Beta</el-tag>
    </div>
    <div v-else>
        <slot></slot>
    </div>
</template>
  
<script>
import { Tag } from 'element-ui'

export default {
    name: "BetaBadge",
    components: {
        [Tag.name]: Tag,
    },
    props: {
        position: {
            type: String,
            default: "center",
            validator: (value) => ["top", "center", "bottom"].includes(value)
        },
        isShow: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        badgePositionClass() {
            return {
                'top-position': this.position === 'top',
                'center-position': this.position === 'center',
                'bottom-position': this.position === 'bottom'
            };
        }
    }
};
</script>
    
<style scoped>
.beta-badge {
    position: absolute;
    right: 13px;
    transform: translate(50%, -50%);
    background-color: #d81b60 !important;
    color: white !important;
    border: none !important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    padding: 0 12px;
}

.top-position {
  top: 0 !important;
}

.center-position {
  top: 7px !important;
}

.bottom-position {
  bottom: 0 !important;
}
</style>
    