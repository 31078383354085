<template>
    <div style="display: flex; flex-direction: column;">
        <el-table
            :data="data.data"
            border
            style="width: 100%; border-bottom: 1px solid #EBEEF5;">
            <!-- Kolom tabel lainnya -->
            <el-table-column
                min-width="120"
                prop="name"
                label="Name">
                <div slot-scope="props">
                    <span style="color: #101111;">{{ props.row.name }}</span>
                </div>
            </el-table-column>
            <el-table-column
                min-width="100"
                prop="type_user"
                label="User Type">
                <div slot-scope="props">
                    <span style="color: #101111;">{{ props.row.type_user }}</span>
                </div>
            </el-table-column>
            <el-table-column
                min-width="120"
                prop="location"
                label="Location">
                <div slot-scope="props">
                    <span style="color: #101111;">{{ props.row.location }}</span>
                </div>
            </el-table-column>
            <el-table-column
                min-width="120"
                prop="user_ip"
                label="IP Address">
                <div slot-scope="props">
                    <span style="color: #101111;">{{ props.row.user_ip }}</span>
                </div>
            </el-table-column>
            <el-table-column
                min-width="120"
                prop="action"
                label="Action">
                <div slot-scope="props">
                    <span style="color: #101111;">{{ props.row.action }}</span>
                </div>
            </el-table-column>
            <el-table-column
                min-width="200"
                prop="description"
                label="Description">
                <template slot-scope="props">
                    <el-popover
                    placement="top"
                    width="320"
                    trigger="hover"
                    :content="props.row.description">
                    <span slot="reference" style="color: #101111; cursor: pointer;">
                        {{ props.row.description.length > 30 ? props.row.description.slice(0, 30) + '...' : props.row.description }}
                    </span>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column
                min-width="135"
                prop="company_name"
                label="Company Name">
                <div slot-scope="props">
                    <span style="color: #101111;">{{ props.row.company_name }}</span>
                </div>
            </el-table-column>
            <el-table-column
                min-width="120"
                prop="created_date"
                label="Created at">
                <div slot-scope="props">
                    <span style="color: #101111;">{{ props.row.create_date }}</span>
                </div>
            </el-table-column>
        </el-table>

        <!-- Pagination -->
        <el-pagination
            background
            layout="prev, pager, next"
            :total="data.total"  
            :current-page="data.current_page"  
            :page-size="data.per_page" 
            @current-change="handlePageChange"
            style="margin-top: 10px;">
        </el-pagination>
    </div>
</template>

<script>
import { Table, TableColumn, Pagination, Popover } from 'element-ui';

export default {
    props: {
        data: {
            type: Object,
            default: () => ({
                data: [],
                current_page: 1,
                last_page: 1,
                total: 0
            })
        },
    },
    watch: {
        "data.data": function (newValue) {
            if(Array.isArray(newValue) && newValue.length == 0) {
                const emptyTextElement = document.querySelector(".el-table__empty-text");
                if (emptyTextElement) {
                    emptyTextElement.textContent = "No Data";
                }
            }
        }
    },
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Pagination.name]: Pagination,
        [Popover.name]: Popover,
    },
    methods: {
        handlePageChange(page) {
            this.$emit('update:data', page);
        },
    }
}
</script>
