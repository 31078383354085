<template>
    <el-table
        :data="tableData"
        border
        style="width: 100%; border-bottom: 1px solid #EBEEF5;"
        >
        <el-table-column
            min-width="50"
            label="No">
            <div slot-scope="props">
                <span style="color: #101111;">{{ props.$index + 1 }}</span>
            </div>
        </el-table-column>
        <el-table-column
            min-width="200"
            prop="target_location"
            label="Target Location">
            <div slot-scope="props">
                <span v-if="props.row.target_location == 'National Targeting'"style="color: #101111;">{{ props.row.target_location }}</span>
                <div v-if="props.row.target_location == 'State'" style="display: flex; flex-direction: column;">
                    <span style="color: #101111;">States:</span>
                    <span style="color: #101111; font-weight: 600; font-size: 12px; word-break: break-word !important;">{{ formatCommaSeparatedString(props.row.states) }}</span>
                    <span v-if="props.row.cities.length >= 1" style="width: 100%; border: 1px solid #e3e3e3;"></span>
                    <span v-if="props.row.cities.length >= 1" style="color: #101111;">Cities:</span>
                    <span v-if="props.row.cities.length >= 1" style="color: #101111; font-weight: 600; font-size: 12px; word-break: break-word !important;">{{ formatCommaSeparatedString(props.row.cities) }}</span>
                </div>
                <div v-if="props.row.target_location == 'Zip Code'" style="display: flex; flex-direction: column;">
                    <span style="color: #101111;">Zip Code:</span>
                    <span style="color: #101111; font-weight: 600; font-size: 12px; word-break: break-word !important;">{{ props.row.zip_code.split("\n").join(", ") }}</span>
                </div>
            </div>
        </el-table-column>
        <el-table-column
            min-width="120"
            prop="keyword"
            label="Keyword">
            <div slot-scope="props">
                <span style="color: #101111; word-break: break-word !important;">{{ props.row.keyword }}</span>
            </div>
        </el-table-column>
        <el-table-column
            min-width="150"
            prop="count_without_geo"
            label="National Count">
            <template #header>
                <div style="display: flex; align-items: center; justify-content: center; gap: 5px; white-space: nowrap; width: 100%;">
                    <span>National Count</span>
                    <el-tooltip :open-delay="300" content="The National Count is the raw 
                    search event count on <br> a national basis. 
                    There are duplicates and also search events that cannot <br> be correlated with an identity" effect="light" placement="top">
                        <i style="cursor: pointer;" class="fa fa-info-circle"></i>
                    </el-tooltip>
                </div>
            </template>
            <div slot-scope="props">
                <span v-if="props.row.status == 'error'" style="color: #101111;">-</span>
                <span v-else-if="props.row.status == 'pending'" style="color: #101111;">-</span>
                <span v-else style="color: #101111;">{{ props.row.count_without_geo.toLocaleString('en-US') }}</span>
            </div>
        </el-table-column>
        <el-table-column
            min-width="150"
            prop="count"
            header-align="center"
            label="Local Count"> 
            <div  slot-scope="props">
                <span v-if="props.row.status == 'error'" style="color: #101111;">-</span>
                <span v-else-if="props.row.status == 'pending'" style="color: #101111;">-</span>
                <span v-else style="color: #101111;">{{ props.row.count.toLocaleString('en-US') }}</span>
            </div>
            <template #header>
                <div style=" display: flex; align-items: center; justify-content: center; gap: 5px; white-space: nowrap; width: 100%" >
                    <span>Local Count</span>
                    <el-tooltip
                    :open-delay="300"
                    content="The Local Count is the deduplicated individually identified records" effect="light" placement="top">
                        <i style="cursor: pointer;" class="fa fa-info-circle"></i>
                    </el-tooltip>
                    <i 
                        :class="sortIcon"
                        style="cursor: pointer; color: gray;"
                        @click="sortData"
                    ></i>
                </div>
            </template>
        </el-table-column>
        <el-table-column
            min-width="120"
            prop="status"
            align="center"
            :filters="[{ text: 'Success', value: 'Success' }, { text: 'No Result', value: 'NoResult' },{text: 'Failed', value: 'Failed'},{text: 'Pending', value: 'Pending'}]"
            :filter-method="filterTagApproxLead" 
            label="Status">
            <div slot-scope="props">
                <div v-if="props.row.status == 'error'" style="display: flex; gap: 4px; align-items: center; justify-content: center;">
                    <span 
                        style="padding: 4px 12px; color: #FFAA1D; border: 1px solid #FFAA1D; background-color: #fff; border-radius: 4px; font-size: 12px; cursor: pointer;"
                        :style="{ opacity: isProccessingLeadCount && !isFinishedApproxLeadCount ? 0.5 : 1, cursor: isProccessingLeadCount && !isFinishedApproxLeadCount ? 'not-allowed' : 'pointer' }"
                        :disabled="isProccessingLeadCount && !isFinishedApproxLeadCount"
                        @click="!isProccessingLeadCount || isFinishedApproxLeadCount ? retryKeyword(props.row) : null"
                    >
                        Retry <i class="fa-solid fa-arrows-rotate"></i>
                    </span>
                    <el-popover placement="top" width="250" trigger="hover">
                        <p style="color: #101111; white-space: normal; word-break: normal; overflow-wrap: anywhere; text-align: left; font-size: 14px; line-height: 1.4;">Server might be busy. Please try again later.</p>
                        <template #reference>
                            <i 
                                class="fa-solid fa-circle-info"
                                style="width: 20px; height: 20px; color: #808080; display: flex; align-items: center; justify-content: center; border-radius: 50%; font-size: 14px; background-color: #e6f7ff;"
                            ></i>
                        </template>
                    </el-popover>
                </div>
                <div v-else-if="props.row.status == 'pending'" style="display: flex; gap: 4px; align-items: center; justify-content: center;">
                    <span 
                        style="padding: 2px 10px; color: #FFAA1D; border: 1px solid #FFAA1D; background-color: #fff; border-radius: 4px; font-size: 12px;"
                    >
                        Pending
                    </span>
                    <el-popover placement="top" width="250" trigger="hover">
                        <p style="color: #101111; white-space: normal; word-break: normal; overflow-wrap: anywhere; text-align: left; font-size: 14px; line-height: 1.4;">Our servers are experiencing heavy usage at this time. Your results may be delayed by more than one hour. You will receive an email when the process is complete.</p>
                        <template #reference>
                            <i 
                                class="fa-solid fa-circle-info"
                                style="width: 20px; height: 20px; color: #808080; display: flex; align-items: center; justify-content: center; border-radius: 50%; font-size: 14px; background-color: #e6f7ff;"
                            ></i>
                        </template>
                    </el-popover>
                </div>
                <span v-else-if="props.row.count > 0"style="padding: 2px 10px; color: #67c23a; border: 1px solid #c2e7b0; border-color: #c2e7b0; background-color: #fff; border-radius: 4px; font-size: 12px;">Success</span>
                <span v-else style="padding: 2px 10px; color: #f56c6c; border: 1px solid #fbc4c4; border-color: #fbc4c4; background-color: #fff; border-radius: 4px; font-size: 12px;">No Result</span>
            </div>
        </el-table-column>
        <el-table-column
            min-width="120"
            prop="created_at"
            label="Created">
            <div slot-scope="props">
                <span style="color: #101111;">{{ props.row.created_at }}</span>
            </div>
        </el-table-column>
    </el-table>
</template>

<script>
import { Table, TableColumn } from 'element-ui';

export default {
    props: {
        data: {
            default: [],
            type: Array,
        },
        isProccessingLeadCount: Boolean,
        isFinishedApproxLeadCount: Boolean
    },
    data() {
        return {
            tableData: [...this.data],
            tableDataOriginal: [...this.data],
            sortOrder: null,
        }
    },
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
    },
    methods: {
        formatCommaSeparatedString(input){
            return input.split(",").map(item => item.trim()).join(", ")
        },
        filterTagApproxLead(value, row){
            if(value === 'Success'){
                return row.count > 0 && (row.status != 'error' && row.status != 'pending' )
            }

            if(value === 'NoResult'){
                return row.count == 0 && (row.status != 'error' && row.status != 'pending' )
            }

            if(value === 'Failed'){
                return row.status == 'error'
            }

            if(value === 'Pending'){
                return row.status == 'pending'
            }
        },
        retryKeyword(value){
            this.$emit('retrykeyword', value);
        },
        sortData() {
            if (this.sortOrder === "asc") {
                this.sortOrder = "desc";
            } else if (this.sortOrder === "desc") {
                this.sortOrder = null;
            } else {
                this.sortOrder = "asc";
            }

            if (this.sortOrder === "asc") {
                this.tableData = [...this.tableData].sort((a, b) => a.count - b.count);
            } else if (this.sortOrder === "desc") {
                this.tableData = [...this.tableData].sort((a, b) => b.count - a.count);
            } else {
                this.tableData = [...this.tableDataOriginal];
            }
        }
    },
    computed: {
        sortIcon() {
            if (this.sortOrder === "asc") return "fa fa-sort-up";
            if (this.sortOrder === "desc") return "fa fa-sort-down";
            return "fa fa-sort";
        },
    },
    watch: {
        data: {
            handler(newValue){
                this.tableData = newValue;
                this.tableDataOriginal = newValue;
            },
            deep: true,
            immediate: true
        }
    }
}
</script>


