<template>
    <div class="skeleton-container">
      <div v-for="n in count" :key="n" class="skeleton-item">
        <div class="skeleton skeleton-checkbox"></div>
        <div class="skeleton skeleton-label"></div>
      </div>
    </div>
</template>
  
<script>
  export default {
    props: {
      count: {
        type: Number,
        default: 12,
      },
    },
  };
</script>
  
<style scoped>
.skeleton-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    margin: 20px auto;
}
  
.skeleton {
    background: linear-gradient(90deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite linear;
}
  
@keyframes loading {
    0% { background-position: 200% 0; }
    100% { background-position: -200% 0; }
}
  
.skeleton-checkbox {
    width: 18px;
    height: 18px;
    border-radius: 4px;
}
  
.skeleton-label {
    width: 100px;
    height: 14px;
    border-radius: 4px;
    margin-left: 8px;
}
  
.skeleton-item {
    display: flex;
    align-items: center;
}
</style>